import { InjectionToken } from '@angular/core';
import { UploadBucketModel } from '@memberspot/models';
import { CustomView } from '@memberspot/shared/model/memberarea';
import { Observable } from 'rxjs';

export const LOGIN_CONFIG = new InjectionToken<string>('login config');

export const AUTH_SERVICE = new InjectionToken<string>('auth service');

export const DEMO_VIEW = new InjectionToken<Observable<boolean>>(
  'is demo view stream',
);

export const BUCKETS = new InjectionToken<UploadBucketModel>('Buckets');

export const LOGIN_CARD_STYLE = new InjectionToken<{ useCardWrapper: boolean }>(
  'login card style',
);

export const CUSTOM_VIEW = new InjectionToken<Observable<CustomView>>(
  'member area custom view',
);
